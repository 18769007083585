<template>
  <div class="governance" id="termsansconditions">
    <div class="ui grid container">
      <div class="intro-content">
        <h3>TayoCash Terms and Conditions</h3>
        <div class="paragraph">
          <h4>1. Introduction</h4>
          <p>
            Kindly read the TayoCash TERMS AND CONDITIONS and ACCEPTABLE USE POLICY, collectively referred here as “T&C,” before you complete your account registration with TayoCash.
          </p>
          <p>
            1.1 The T&C shall govern your access and use of all the services offered by TayoCash, including, but not limited to, the TayoCash website, TayoCash App, and all the features and functions associated with it, which are collectively referred here as “TayoCash Service.” By accessing, registering, or using the TayoCash Service, you accept and agree to be legally bound by this T&C.
          </p>
          <p>
            1.2 The T&C shall be effective and binding until your membership is terminated by either you, or TayoCash, except for those provisions that shall survive or remain effective after termination as provided in the T&C and applicable laws and regulations. If you do not understand or do not wish to be bound by the T&C, you should not access or use any portion of the TayoCash Service.
          </p>
          <p>
            1.3 TayoCash reserves the right to modify the T&C at any time without prior notice. Any change to the T&C will take effect immediately upon publishing and/or on the assigned effective date. Using the TayoCash Service after changes have been made in the T&C confirms your agreement to such changes.
          </p>
          <p>

            1.4 Under this T&C, “we”, “us”, and “our” refer to TayoCash, Inc., its shareholders, officers, directors, employees, affiliates, successors, and assigns. TayoCash, Inc. is a remittance and electronic money issuer company, duly registered with and licensed by the Bangko Sentral ng Pilipinas (BSP).
          </p>
          <p>
            1.5 On the other hand, the terms “you” and “your” refer to users and those who will avail of the TayoCash Service such as, but not limited to, its Members, Partners, Merchants, billers, senders, recipients, and such other parties, individual or juridical, using, availing of, and accessing the TayoCash Service.
          </p>
        </div>
        <div class="paragraph">
          <h4>2. Definition of Terms</h4>
          <p>The terms used in the T&C shall have the following definitions as stated below:</p>
          <h5>Biller</h5>
          <p>This refers to any company accredited by TayoCash to accept bill payments using TayoCash.<p>

          <h5>Cash In </h5>
          <p>This refers to the act of loading or transferring funds into your TayoCash Wallet, where physical cash or funds coming from a linked or enrolled bank account are converted into electronic money and credited to the TayoCash Wallet.<p>

          <h5>Cash Out</h5>
          <p>This refers to the conversion of the electronic money stored in the TayoCash Wallet into physical cash, or the transfer of TayoCash Wallet balance to a linked or enrolled bank account.</p>

          <h5>Fund Transfer</h5>
          <p>This refers to the sending and receiving of electronic money from one TayoCash Wallet to another TayoCash Wallet.</p>

          <h5>KYC</h5>
          <p>KYC is an acronym for “Know-Your-Customer.” It refers to the  process of verifying and establishing the identity of Members and other users of the TayoCash Service such as, but not limited to, billers, senders, recipients, and the like, as required by the BSP and the Anti Money Laundering Act (AMLA) of 9160, as amended.</p>

          <h5>Member</h5>
          <p>This refers to an individual or juridical entity who signs up and registers for a TayoCash Account.</p>

          <h5>Merchant</h5>
          <p>This refers to an individual or juridical entity accredited by TayoCash to provide Pay Load, Pay Bills, Cash In and Cash Out, and/or Remittance services (for qualified Merchants), and accept TayoCash as a mode of payment for purchases of goods and services through the TayoCash Pay QR service. All Merchants are required to register as a Member first before they can be accredited as a Merchant.</p>

          <h5>Partner</h5>
          <p>This refers to an individual or juridical entity accredited by TayoCash to provide Pay Load, Pay Bills, Cash In and Cash Out, and/or Remittance services (for qualified Partners).</p>

          <h5>Pay Bills</h5>
          <p>This refers to the TayoCash Service that enables Members to pay bills such as utility bills, credit card bills, taxes, association and membership dues, insurance premiums, tuition and fees, and other types of charges, to billers accredited by TayoCash.</p>

          <h5>Pay QR</h5>
          <p>This refers to the TayoCash Service that allows Members to pay for the purchase of goods and/or services by scanning the TayoCash QR code of the Merchant. Payment via Pay QR may be completed in physical or online stores.</p>

          <h5>Recipient</h5>
          <p>This refers to a Member or non-member who receives money using the TayoCash Remittance service.</p>

          <h5>Remittance</h5>
          <p>This refers to the TayoCash Service that allows Members and non-members to send and receive cash through accredited Partners and Merchants.</p>

          <h5>Sender</h5>
          <p>This refers to a Member or non-member who sends money using the TayoCash Remittance service.</p>

          <h5>TayoCash</h5>
          <p>This refers to TayoCash, Inc., a remittance and electronic money issuer company, duly registered with and licensed by the Bangko Sentral ng Pilipinas (BSP). This includes the company’s shareholders, officers, directors, employees, affiliates, successors, and assigns.</p>

          <h5>TayoCash Account or Wallet</h5>
          <p>This refers to the reloadable electronic account identified and connected to your mobile Subscriber Identity Module (SIM), and stored within the TayoCash system. It stores electronic money that has the same monetary value as its cash equivalent, hence it can be used by Members to avail of TayoCash Services, such as, but are not limited to, payment of purchase of goods and services, fund transfers, etc. and may only be redeemed or withdrawn at face value. It is not a depository account and, therefore, not covered by the Philippine Deposit Insurance Corporation (PDIC). It does not earn interest. It is subject to the rules and regulations of the BSP, the Anti Money Laundering Act (AMLA) of 9160, as amended, and the Data Privacy  Act of 2012, and their implementing rules and regulations.</p>

          <h5>TayoCash App</h5>
          <p>This  refers to the TayoCash mobile service application downloadable on mobile phones, tablets, and other capable electronic devices where Members can avail of the TayoCash Service.</p>
        </div>
        <div class="paragraph">
          <h4>3. TayoCash Wallet Registration</h4>

          <p>3.1 When you become a TayoCash Member and accept the TayoCash T&C, this means that you have agreed to associate your mobile number with your TayoCash Account.  It also means that you agree to maintain the registered TayoCash mobile number for the duration of the effectiveness of your TayoCash membership. Any change in the registered number shall be covered by a written request subject to the verification and approval of TayoCash. Any and all transactions performed or concluded using your registered mobile number prior to the approval of such request shall still be considered under your TayoCash Account.</p>

          <p>3.2 You agree to provide information in order for us to verify your identity when you register for a TayoCash Account or Wallet  in accordance with the KYC requirements of BSP. These requirements pertain to information such as, but not limited to, your complete name, date and place of birth, present and/or permanent address, contact number, citizenship or nationality, source of funds, video/photo of yourself, signature and/or biometric, and official documents proving your identity such as a government-issued ID, Philsys ID/ePhilsys ID, passport, Alien Certificate of Registration, and other similar means of verifying your identification that are recognized and allowed by law.</p>

          <p>3.3 You agree that we have the option and authority to verify your registration information from any third party, as we may deem necessary.</p>

          <p>3.4 You commit to provide us with accurate and complete information and shall update us promptly of any changes. You are amenable that you may be required to fill out and submit the necessary forms, with supporting documents, to update your information.</p>

          <p>3.5 You agree to provide all necessary information and render all reasonable assistance and cooperation that we may require in order to complete the verification of the information you have submitted. Any and all information that you will provide will be used to determine your eligibility to register or open and/or continue to use your TayoCash Account or Wallet  and/or avail of the TayoCash Service.</p>

          <p>3.6 We reserve the right to deny, close, suspend, or limit your access to your TayoCash Account or Wallet and/or your use of the TayoCash Service if we are unable to obtain or verify your information, or if any of the information you have provided is found to be false, misleading, or incorrect. The collection and use of your personal information shall be in accordance with the provisions of our <a class="link" href="/privacypolicy">Privacy Policy</a>, which may be revised and updated by us from time to  time.</p>

          <p>3.7 If a certain TayoCash Service becomes available or will be offered only on a selective or promotional basis, supplementary information may be required from your end in order to enable or avail such service. Your ability to provide such supplementary information will determine your eligibility to such TayoCash Service or promotions.</p>

          <p>3.8 You agree to download the TayoCash App and its updates from Google Play or Apple Store or at such other app stores only that we may authorize. We shall not be liable for any damage or loss resulting from defective applications originating from unauthorized stores or sources. For any questions, inquiries, and concerns about the TayoCash App, you may get in touch with our Customer Care Representatives via the <a class="link" href="/helpcenter">Help Center</a> in the TayoCash App or website, or via email at <a class="link" href="mailto:ccg@tayocash.com">ccg@tayocash.com</a>, or via the hotline number <span class="vtxt-align">(02) 8888-TAYO (or 8888-82-96)</span>.</p>

          <p>3.9 You confirm that you have read and understood all the provisions of the T&C and agree to abide by it.</p>

          <p>3.10 Individual Account Registration
            <span>3.10.1 To register for an individual TayoCash membership, you agree that you are at least eighteen (18) years old. Parental or guardian consent shall be required for ages below eighteen (18) years old. You will be asked to provide additional information such as, but not limited to, parent or guardian’s name, mobile number, and valid ID, as well as your address, date of birth, nationality, etc. depending on the TayoCash Service you wish to avail.</span>
          </p>
          <p>3.11 Corporate Account Registration
            <span>3.11.1 To register a business entity for TayoCash membership, you agree that you are duly authorized by law to operate and conduct business. You further agree that you are authorized to open and register a TayoCash Account on behalf of the business, and that your officers, employees, representatives, or agents who will be designated to access and use the TayoCash Service are with authority and discretion to legally bind you and your business to TayoCash and the T&C. You also agree to submit the necessary documents to support such authorities.</span>

            <span>3.11.2 In accordance with the KYC requirements of the BSP, you shall provide information and documents, such as, but not limited to, name and nature of business, official business address, specimen signatures or biometrics of authorized signatory/ies, certificates of registration with DTI or SEC, secondary license or authority, articles  of incorporation/partnership, latest General Information Sheet, board/partners’ resolution or special power of attorney containing the authority to register for a TayoCash Account.</span>
          </p>
        </div>
        <div class="paragraph">
          <h4>4. TayoCash Services</h4>
          <p>4.1 Cash In and Cash Out
            <span>4.1.1 Members can Cash In and Cash Out through accredited Partners and Merchants of TayoCash and partner bank/s, which are listed in the TayoCash App and in the TayoCash website.</span>

            <span>4.1.2 Cash In and Cash Out transactions are deemed authorized and conclusive once accepted by the Partner or Merchant or partner bank, and TayoCash.</span>

            <span>4.1.3 You agree to exercise care in entering transaction details and to comply with the instructions and security protocols provided by the TayoCash App. TayoCash shall not be liable for loss, damage, or refund arising from any error or mistake committed by you in the entry of such details, or for your failure to comply with the TayoCash App instructions and security protocols, or for any dispute between you and the Partner or Mechant or partner bank arising from such transaction.</span>

            <span>4.1.4 Cash In and Cash Out amounts are subject to minimum and maximum limits and applicable charges that may be imposed by TayoCash in accordance with the rules and regulations imposed by the BSP.</span>
          </p>

          <p>4.2 Buy Load
            <span>4.2.1 Buy Load allows you to purchase prepaid load, for mobile phone services or gaming credits, from third-party load service providers through the TayoCash App.</span>

            <span>4.2.2 Once you have purchased and completed a Buy Load transaction, in accordance with the TayoCash App instructions and security protocols, and TayoCash has accepted it, the transaction cannot be disputed by you. The transaction reference number in the SMS and/or in-app notifications shall be your proof of purchase to the third-party load service provider.</span>

            <span>4.2.3 You agree to exercise care in entering the correct transaction details and to follow the instructions and security protocols required by the TayoCash App. TayoCash shall not be liable for loss, damage, or refund arising from any error or mistake committed by you in the entry of such details, or for any unauthorized  transaction due to your failure or negligence to comply with the TayoCash App instructions and security protocols, or for any dispute between you and the third-party load service provider.</span>
          </p>

          <p>4.3 Pay QR
            <span>4.3.1 Pay QR allows you to pay using your TayoCash Wallet balance for on-site and off-site purchases of goods and services simply by scanning the unique QR code displayed in the physical or online stores of our accredited Merchants.</span>

            <span>4.3.2 Once you perform a Pay QR transaction, you bind yourself to the terms and conditions of the Merchant and hold TayoCash free from any liability due to any breach of such terms and condition, such as damaged, defective, or undelivered goods, non-performance of services, and after-sales services related to the availment of such goods and/or services, and any other dispute between you and the Merchant arising from the transaction.</span>

            <span>4.3.3 In the performance of a Pay QR transaction, you further agree to follow the instructions and security protocols directed by the TayoCash App. Once the said transaction is accepted by TayoCash, the transaction is deemed authorized and valid, and TayoCash shall not be liable and accountable for any erroneous or unauthorized transaction due to your mistake, negligence, or failure to comply with the TayoCash App instructions and security protocols.</span>
          </p>

          <p>4.4 Pay Bills
            <span>4.4.1 Pay Bills allows you to pay for utility and/or other services to a biller through the TayoCash App. Accredited billers of Tayocash are listed in the TayoCash App. TayoCash reserves the right to remove or add the billers in the list.</span>

            <span>4.4.2 Once you have authorized and performed a Pay Bill transaction, in accordance with the TayoCash App instructions and security protocols, and TayoCash has accepted it, the transaction cannot be disputed by you. The transaction reference number in the SMS and/or in-app notifications shall be your proof of payment to the biller.</span>

            <span>4.4.3 You agree to exercise care in entering payment details and to follow instructions and security protocols required by the TayoCash App. TayoCash shall not be liable for loss, damage, or refund arising from any error or mistake committed by you in the entry of such details, or for your failure to comply with the TayoCash App instructions and security protocols, or for any dispute between you and the biller arising from such transaction.</span>
          </p>

          <p>4.5 Fund Transfer
            <span>4.5.1 You can transfer funds from your TayoCash Wallet to another TayoCash Wallet, following the instructions and security protocols required by the TayoCash App and subject to minimum and maximum limits plus applicable fees that may be imposed by TayoCash.</span>

            <span>4.5.2 TayoCash shall be not held liable and accountable for any erroneous or unauthorized transaction, or for claims, damages, and losses due to your mistake, negligence, or failure to comply with the TayoCash App instructions and security protocols.</span>

            <span>4.5.3 If your TayoCash Wallet receives funds that are not intended for you, you shall immediately notify TayoCash. With or without notice to and from you, TayoCash shall have the authority to recover the funds that were erroneously transferred to your TayoCash Wallet and credit it back to the source account.</span>
          </p>

          <p>4.6 Remittance
            <span>4.6.1 Members and non-members can use the TayoCash Remittance service in sending and receiving cash via our accredited Partners and Merchants, subject to  prescribed TayoCash remittance fees and upon compliance with the KYC requirements of TayoCash and our accredited Partners and Merchants.</span>

            <span>4.6.2 KYC requirements shall include the submission of your personal information, such as, but not limited to, the name and contact details of both Sender and Recipient, a valid government-issued ID, and other similar forms of identification that may be required by TayoCash and our accredited Partners or Merchants.</span>

            <span>4.6.3 Accredited Partners or Merchants may refuse to accept and/or release remittance if the required information is not provided or complied with.</span>
          </p>
        </div>
        <div class="paragraph">
          <h4>5. TayoCash Wallet Limit and Usage</h4>
          <p>5.1 A Member may be allowed to maintain more than one TayoCash Account registered under his/her/its name, subject to the sole discretion and conditions that may be imposed by TayoCash and the applicable laws, rules, and regulations of the BSP on transaction and wallet balance limits and other conditions.</p>

          <p>5.2 If a Member is allowed more than one TayoCash Account, TayoCash Wallet limits shall be applied on a per member basis and shared across all the multiple TayoCash Accounts or Wallets.</p>

          <p>5.3 The TayoCash Wallet limit may depend on the membership type and verification level of the Member, subject to the proper KYC and verification process by TayoCash.</p>

          <p>5.4 TayoCash has the option, at any time, to change such transaction and wallet balance limits and impose other restrictions on the use of the TayoCash Wallet.</p>
        </div>
        <div class="paragraph">
          <h4>6. Activation and Deactivation of Tayocash Wallet and Features</h4>

          <p>6.1 Your TayoCash Account shall be activated upon your compliance with our registration requirements and our determination and discretion that you are eligible to use the TayoCash Service.</p>

          <p>6.2 TayoCash has the option, at any time, to introduce new and additional features and/or alter, replace, or deactivate existing features in the TayoCash Wallet, without obligation on its part to give prior notice.</p>

          <p>6.3 Members shall be solely responsible to check for updates and download such updates of the TayoCash App on their mobile phones. TayoCash shall not be held liable if certain features, services, or functions may be disabled or become inoperative due to the failure or refusal of the Member to update the TayoCash App.</p>

          <p>6.4 TayoCash may opt to deactivate, suspend, cancel, or terminate a Member’s privilege to use a particular feature or the Member’s TayoCash Account altogether, whenever it deems appropriate and necessary, or believes that the TayoCash Service may be or is being used for fraudulent or illegal activities, or in breach of the T&C, laws, and implementing rules and regulations. Notwithstanding any provision in this T&C to the contrary, TayoCash reserves the right to take legal action against any erring Member and/or other parties who may be involved in the fraudulent or illegal activities, in order to protect the general public and TayoCash’s name and reputation.</p>

          <p>6.5 TayoCash shall have absolute discretion and authority to approve or deny any request for reactivation of a deactivated or suspended TayoCash Account and/or to impose a reactivation fee.</p>

          <p>6.6 TayoCash shall not be liable and accountable for any unauthorized use of a TayoCash Account due to the failure or negligence of the Member to safeguard his/her/its mobile phone, password or passcode, and to adhere to any other security protocols that may be required by TayoCash. Members are bound and liable for any and all the transactions conducted, availed, or concluded using their TayoCash Account and password or passcode.</p>
        </div>
        <div class="paragraph">
          <h4>7. TayoCash Loyalty Program</h4>
          <p>7.1 TayoCash shall have the discretion to create, develop, adopt, and pursue a loyalty program upon the download of the TayoCash App, as well as the use of the TayoCash Service and the TayoCash Wallet. Your rights and obligations under the loyalty program shall be covered by the terms and conditions specific to the said program.</p>
        </div>
        <div class="paragraph">
          <h4>8. Fees, Rates and Other Charges</h4>
          <p>8.1 TayoCash shall have the absolute discretion to impose, whenever it deems appropriate, any amount of fees, and charges for the use of the TayoCash Wallet, including the use of any of its features, such as, but not limited to, Cash In and Cash Out fees, Pay QR and Pay Bills fees, fund transfer fees, reactivation fees, dormancy fees, processing fees, penalties for violations of this T&C, and such other reasonable fees and charges that may be imposed for the use of the TayoCash Service.</p>

          <p>8.2 Members shall be notified of any change in the amount of fees and charges at least thirty (30) days before its effectivity. Notice of such change shall be made in the TayoCash Website and/or any other official TayoCash official communication channel such as, but not limited to, its social media accounts, in-app notifications, and email notifications.</p>

          <p>8.3 Fees and other charges, as applicable, shall be inclusive of all applicable Philippine taxes and shall be debited from the Member’s TayoCash Wallet or paid up front, as applicable.</p>

          <p>8.4 A dormancy fee shall be charged for TayoCash Accounts that have not been used for any monetary TayoCash transaction for two (2) years from the last date of  transaction. A dormancy fee may be charged, which shall be automatically debited from the Member’s TayoCash Wallet every 10th day of the month without need of prior consent or approval by the Member. If the TayoCash Wallet remains dormant in the third year, it shall be terminated and any wallet balance shall be forfeited in favor of TayoCash.</p>

          <p>8.5 For reactivation of a terminated account, a reactivation fee may be charged upon submission and verification of updated KYC requirements and such other conditions that may be required by TayoCash.</p>
        </div>
        <div class="paragraph">
          <h4>9. Transaction History Statement</h4>
          <p>9.1 Members can view or download their transaction history from the TayoCash App. Access to the transaction history is protected by the password or passcode created by the Member. Members are solely responsible for the safekeeping of such password or passcode, and shall hold TayoCash free from any consequence arising from unauthorized use of the password or passcode, or access to the transaction history.</p>

          <p>9.2 TayoCash, at any time, may introduce new security enhancement measures to protect the confidentiality of the transaction history.</p>
        </div>
        <div class="paragraph">
          <h4>10. Disputes and Erroneous Transactions</h4>
          <p>10.1 The details in the confirmation message sent either through SMS or in-app notifications and entries in the transaction history are presumed true and correct, unless the Member notifies TayoCash in writing to dispute the information contained in the sent SMS or in-app notifications and/or entries in the transaction history, within a period of fifteen (15) days from the date of transaction. The absence of a written dispute sent within the period stated entails that the transactions are conclusively true and correct without any kind of qualification.</p>

          <p>10.2 The filing, investigation, and resolution of disputes shall be in accordance with the guidelines and procedures that may be set by TayoCash pursuant to applicable laws.</p>

          <p>10.3 Disputed transactions shall only be credited back to the Member’s TayoCash Wallet once the dispute has been properly processed, investigated, and resolved in favor of the Member.</p>

          <p>10.4 Members may reach out to our Customer Care Representatives via the <a class="link" href="/helpcenter">Help Center</a>  in the TayoCash App or website, or via email at <a class="link" href="mailto:ccg@tayocash.com">ccg@tayocash.com</a>, or via the Customer Care Service Hotline number <span class="vtxt-align">(02) 8888-TAYO (or 8888-82-96)</span>.</p>
        </div>
        <div class="paragraph">
          <h4>11. Fraudulent Transactions</h4>

          <p>11.1 TayoCash shall have the right to automatically suspend or block the use of the TayoCash Account if there is any reason to believe that the TayoCash Account is being used without the authority of the registered Member, or the TayoCash Account or Service shall  be used or is being used for fraudulent, suspicious, or illegal transactions, or in breach of the T&C, laws, and regulations.</p>

          <p>11.2 TayoCash is not obligated to inform the Member prior to suspending or blocking the TayoCash Account pursuant to this section. The Member acknowledges the authority of TayoCash to suspend or block the TayoCash Account accordingly and shall hold TayoCash free from any and all losses, damages, or consequences of such suspension or blocking.</p>

          <p>11.3 Without giving any reason or notice, and without prejudice to the other provisions as stated, TayoCash has the absolute discretion to:

            <span>11.3.1 Reject any TayoCash transaction even if there is sufficient available balance;</span>

            <span>11.3.2 Suspend or cancel or terminate the Member’s right to use the TayoCash Account;</span>

            <span>11.3.3 Increase or decrease the balance limit of a TayoCash Wallet;</span>

            <span>11.3.4 Refuse to reactivate a TayoCash Account or feature, which has been previously deactivated;</span>

            <span>11.3.5 Introduce, amend, vary, restrict, terminate, or withdraw the benefits, services, facilities, and privileges with respect to or in connection with a TayoCash Account, whether relating to a specific Member or Members, or to all Members in general; and </span>

            <span>11.3.6 Avail of other remedies provided by applicable laws and regulations.</span>
          </p>
        </div>
        <div class="paragraph">
          <h4>12. Mobile Phone, TayoCash-Registered SIM/Phone Number, and TayoCash Wallet Security</h4>

          <p>12.1 The security, confidentiality, and proper care of the TayoCash Account, the TayoCash-registered Subscriber Identity Module (SIM)/phone number, and any registered electronic equipment that can be used to access a TayoCash Account or the TayoCash Service shall be the sole responsibility of the Member. All transactions made using the TayoCash Account are conclusively presumed to be made by the Member, and the Member shall be held liable for all such transactions.</p>

          <p>12.2 In case of loss of the TayoCash registered SIM or registered electronic equipment, the Member shall immediately inform TayoCash of such loss. This incident may be reported to our Customer Care Representatives via the <a class="link" href="/helpcenter">Help Center</a> in the TayoCash App or website, or via email at <a class="link" href="mailto:ccg@tayocash.com">ccg@tayocash.com</a>, or via the Customer Care Service Hotline number <span class="vtxt-align">(02) 8888-TAYO (or 8888-82-96)</span>.</p>

          <p>12.3 Suspension of the TayoCash Account due to loss of the TayoCash registered SIM or any registered electronic equipment shall be processed only upon compliance with the proper authentication procedures and submission of the required documents that may be required by TayoCash. Any and all transactions concluded prior to the report of loss shall be for the account of the Member.</p>
        </div>
        <div class="paragraph">
          <h4>13. Limits on Liability</h4>
          <p>13.1 TayoCash makes no warranty, express or implied, regarding any of the TayoCash Service. The TayoCash Service is offered on an “AS IS”, “AS AVAILABLE” basis and without warranties of any kind, other than warranties that are incapable of exclusion, waiver, or limitation under the laws applicable to the T&C. Without limiting the generality of the foregoing, TayoCash makes no warranty as to:

            <span>13.1.1 The content, quality, or accuracy of data or information provided by TayoCash or received and/or transmitted using the TayoCash Service;</span>

            <span>13.1.2 Any product and service obtained using the TayoCash Service;</span>

            <span>13.1.3 The TayoCash Service being uninterrupted or error-free; and</span>

            <span>13.1.4 Any particular result or information that will be obtained.</span>
          </p>

          <p>13.2 TayoCash shall not be liable for any loss, cost, compensation, damage, or liability to a Member or to third parties arising from, directly or indirectly, or as a result of any or all of the following:

            <span>13.2.1 Refusal to allow, accept, or honor TayoCash transactions or any payment transaction through TayoCash by TayoCash Partners, Merchants, billers, banks, financial institutions, ATMs, and other similar establishments or facilities;</span>

            <span>13.2.2 When TayoCash is honored by any bank, financial institution, Partner or Merchant but payment transaction is not authorized, for any reason whatsoever;</span>

            <span>13.2.3 Inability to perform or complete any TayoCash Service due to service/system/internet/line unavailability or instability;</span>

            <span>13.2.4 Delay, interruption, or termination of the TayoCash transaction, whether due to administrative error, technical, mechanical, electrical, or electronic fault or difficulty, or any other reason or circumstance beyond TayoCash's control (including but not limited to acts of God, pandemic, strike, labor disputes, fire, disturbance, action of government, atmospheric conditions, lightning, interference or damage by third parties, or any change in legislation);</span>

            <span>13.2.5 Theft or unauthorized use of the TayoCash Account, or any loss, costs, or damages payable to any third party;</span>

            <span>13.2.6 Any misrepresentation or fraud committed by, or misconduct of the Member, Partners, Merchants, billers, or third parties, such as, but not limited to, owners, representatives, employees, or agents of the Member, Partners, Merchants, billers; and</span>

            <span>13.2.7 Other circumstances similar to the above.</span>
          </p>
          <p>
            13.3 In the event an action is filed against TayoCash by a Member, the Member agrees that TayoCash, if, and when TayoCash is found and proven to be at fault, shall only be liable for up to ONE THOUSAND PESOS (PHP1,000.00) or the amount of the damages actually suffered by the Member, whichever is lower.
          </p>
        </div>
        <div class="paragraph">
          <h4>14. Data Privacy Notice</h4>
          <p>14.1 By accessing and/or availing the TayoCash Service, you consent and authorize TayoCash, its subsidiaries, affiliates and partners, agents and third-party service providers to collect, process, use, and disclose your information, including sensitive and personal information as defined and in accordance with the Data Privacy Act of 2012. Information shall include:

            <span>14.1.1 Your name, date and place of birth, gender, marital status, age, nationality, residence, office and email addresses, personal and business mobile numbers, specimen signature or biometrics, videos and photos, among others;</span>

            <span>14.1.2 Information gathered from government records such as, but not limited to, information from your Philsys ID/ePhilsys ID, passport, SSS, Philheath, BIR, SEC, DTI, NBI, courts, and other government agencies;</span>

            <span>14.1.3 Your employment and/or business records and information;</span>

            <span>14.1.4 Your financial information, such as source of income and/or funds;</span>

            <span>14.1.5 Your bank and credit card details;</span>

            <span>14.1.6 Your account activities, transaction details, movements, and interactions with our Partners and Merchants when you access  and/or avail of the TayoCash Service;</span>

            <span>14.1.7 The history and frequency of your use of the TayoCash Service;</span>

            <span>14.1.8 The device, IP address, operating system, browser type and version, and other machine identifiers that you use when you access and/or use the TayoCash Service;</span>

            <span>14.1.9 Your location if you use TayoCash location-based content, products, and services;</span>

            <span>14.1.10 All communication, including voice recordings, with you by our employees, authorized representatives, agents and service providers such as your comments, feedback, responses to surveys, and the likes;</span>

            <span>14.1.11 Anonymous information and data, generated by analytics tools using cookies or similar technologies to enhance customer experience, whenever you electronically communicate with us or access or use the TayoCash Service, our website, social media sites, mobile applications and other channels; and</span>

            <span>14.1.12 Such other information where required or permitted by law, or with your prior consent.</span>
          </p>

          <p>14.2 You further consent and authorize TayoCash to use or share these information with its subsidiaries, affiliates and partners, agents and third-party service providers, and other parties, under an obligation of confidentiality and with adequate safeguards for data privacy and security, as part of its business operations and the provision of its products and services, and for processing, statistical, marketing, research, and other specified legitimate purposes, subject to applicable laws and regulations, and with other parties upon your consent, or if personal information is publicly available, or when required or permitted by law.</p>

          <p>14.3 TayoCash, at its option, may likewise transfer, store, and/or outsource the processing of your personal information outside the Philippines, subject to appropriate security measures and agreements and the requirements of the Data Privacy Act and other applicable laws for processing personal information and data.</p>

          <p>14.4 You may access, correct, and update your personal information in our records. We may charge a fee for processing your request/s for access and/or updates, depending on the nature or complexity of the request. The information on the processing fee shall be made available to you prior to making the request. You may reach the Customer Care Service Hotline <span class="vtxt-align">(02) 8888-TAYO (or 8888-82-96)</span> for any such requests. For concerns relating to how we collect, use, share, or  process your information, you may email <a class="link" href="mailto:dataprivacyofficer@tayocash.com">dataprivacyofficer@tayocash.com</a>.</p>
        </div>
        <div class="paragraph">
          <h4>15. Settlement of Disputes and Controversies by Arbitration Only</h4>

          <p>15.1 Any dispute, controversy, difference, or claim arising out of or in relation to the T&C, including questions as to the interpretation, implementation, existence, validity, breach, or termination thereof, or as to any non-contractual obligation arising out or relating thereto, shall be referred to and finally resolved by arbitration administered by the Philippine International Center for Conflict Resolution (PICCR) in accordance with the PICCR Arbitration Rules in force at the time of the commencement of the arbitration which rules are deemed incorporated by reference in this clause.</p>

          <p>15.2 The arbitration shall be conducted by one arbitrator to be appointed in accordance with the PICCR arbitration rules. The seat of arbitration shall be in the Philippines. The language of the arbitration shall be in English. The arbitration agreement shall be  governed by the laws of the Philippines.</p>
        </div>
        <div class="paragraph">
          <h4>16. Non-Waiver of Rights</h4>

          <p>16.1 Failure, omission, or delay on the part of TayoCash to exercise its rights or remedies under this T&C shall not operate as a waiver. Any such waiver shall be valid only when reduced in writing.</p>
        </div>
        <div class="paragraph">
          <h4>17. Separability of Clause</h4>

          <p>17.1 Should any term or condition in this T&C be rendered void, illegal, or unenforceable in any respect under any law, the validity, legality and enforceability of the remaining terms and conditions shall not be affected or impaired.</p>
        </div>
        <div class="paragraph">
          <h4>18. Agreement</h4>

          <p>18.1 You, as a TayoCash member, agree to be bound by the provisions of the T&C, Acceptable Use Policy, and the Privacy Policy governing the issuance and use of the TayoCash Service. Should you disagree with the provisions of the T&C, the Acceptable Use Policy and the Privacy Policy, you shall delete and remove the TayoCash App and Website from your mobile phone and other related devices, and submit a written notice of cancellation to TayoCash Customer Care Group, otherwise, you shall continue to be bound by the T&C, Acceptable Use Policy and Privacy Policy and be liable for all charges incurred for the use of the TayoCash Service.</p>
        </div>
        <div class="paragraph">
          <h4>19. Amendments</h4>
          <p>19.1 TayoCash may, at any time and for whatever reason it may deem proper and necessary, amend, revise, or modify the provisions of the T&C, Acceptable Use Policy, and Privacy  Policy without prior notice. It is the responsibility of the Member to regularly check any change to the provisions of the T&C, Acceptable Use Policy, and Privacy Policy. The continued use of the TayoCash Service by the Member after any such changes constitutes acceptance of the new terms and conditions. Failure of the Member to inform TayoCash of his/her/its intention to terminate his/her/its TayoCash Wallet or Account  shall be construed as acceptance of such amendments.</p>
        </div>
        <div class="paragraph">
          <h4>20. Customer Complaints Handling Process</h4>
          <p>20.1 Financial consumer protection is paramount to us. If you have concerns or have encountered any problem with our service, or have suggestions on how we can improve our service, you may contact our Customer Care Service Group via the <a class="link" href="/helpcenter">Help Center</a> in the TayoCash App or website, or via email at <a class="link" href="mailto:ccg@tayocash.com">ccg@tayocash.com</a>, or via the hotline number <span class="vtxt-align">(02) 8888-TAYO (or 8888-82-96)</span>.</p>

          <p>20.2 All information disclosed shall be treated with utmost confidentiality and will be resolved in the most efficient and effective manner.<br>
          In line with our partnership with BSP, you may also contact the following:

          <span>
            BSP’s Financial Consumer Protection Department:<br>
            <ul class="nospace">
              <li>(632) 8708.7087 or (632) 8708.7088; or </li>
              <li>Email at <a class="link" href="mailto:consumeraffairs@bsp.gov.ph">consumeraffairs@bsp.gov.ph</a></li>
            </ul>
          </span>
          </p>
        </div>

        <h3>Acceptable Use Policy</h3>
        <div class="paragraph">
          <h4>1. The TayoCash Service</h4>
          <p>As a TayoCash Member, you undertake and agree NOT to:</p>

          <p>1.1 Incorporate any word in your name, message identification, or custom user title that is defamatory, obscene or profane, or which violates any trademark, service mark, or other intellectual property rights of any third party, including that of TayoCash. You likewise agree not to use any trademark, trade name, service mark, or logo in a way that is likely or intended to cause confusion about the owner or authorized user of such marks, names, or logo;</p>

          <p>1.2 Alter, modify, or cause the alteration or modification of the TayoCash Service,  without prior written consent from TayoCash. You further agree not to use the TayoCash Service for any commercial use, without prior written authority from  TayoCash;</p>

          <p>1.3 Use any “deep-link”, “page-scrape”, “robot”, “spider”, or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy, or monitor any part of the TayoCash Service, or in any way reproduce or circumvent its navigational structure or presentation, as well as to obtain or attempt to obtain any material, document, or information through any means not purposely made available through the TayoCash Services;</p>

          <p>1.4 Gain or attempt to gain unauthorized access to any part or feature of the  TayoCash Service or to any other system or network connected to the TayoCash Service. You also agree not to gather, harvest, or otherwise collect information about others using the TayoCash Service without their explicit informed consent; nor restrict, prevent, or prohibit any other party from using the TayoCash Service, including but not limited to such actions, which may tend to discourage others from using the TayoCash Service, such as stalking, flaming, or the lashing out at other parties, spamming or the sending of unsolicited information, advertisement or content, flooding or the sending of repetitive message, trolling or the use of insulting or deliberately divisive information, material or content, other forms of annoyances, and the like;</p>

          <p>1.5 Circumvent, disable, or otherwise interfere with security-related features of the TayoCash Service, including those that prevent or restrict the use or copying of any content, material, or information available on or through the TayoCash Service, as well as those that enforce limitations on the use of the TayoCash Service;</p>

          <p>1.6 Probe, scan, or test the vulnerability of the TayoCash Service or any network  connected to it, and not to breach the security or authentication measures on the  same. You agree not to reverse look-up, trace, or seek to trace, to its source, any information on any user of or visitor to the TayoCash Service, or any other customer of TayoCash including any account maintained with the TayoCash Service not owned by you, or exploit the TayoCash Service or any information made available or offered by or through the TayoCash Service, in any way where the purpose is to reveal any information, including, but not limited to, personal information other than your own information;</p>

          <p>1.7 Use or access the TayoCash Service for your information and personal use solely as intended through the provided functionality of the TayoCash Service. You agree not to copy or download any material or content from or through the TayoCash Service unless such copying or downloading is explicitly allowed by a visible manifestation thereof such as a “download” button or a similar link ostensibly displayed. You further agree not to engage or attempt to engage in the use, copying, transmission, broadcast, display, distribution, or sale of any of the contents, material, or information available on or through the TayoCash Service, including user comments and the like, other than as expressly permitted herein or as explicitly indicated in the TayoCash Service, including the use thereof for commercial purposes;</p>

          <p>1.8 Take any action that imposes an unreasonable or disproportionately large load on or to introduce any malware or virus to the infrastructure of the TayoCash Service or its systems or networks, or any systems or networks connected to the TayoCash Service that will severely and adversely affect the function and operation of the TayoCash App and/or Website;</p>

          <p>1.9 Use any device or routine to interfere or attempt to interfere with the proper  working of the TayoCash Service or any transaction being conducted using the TayoCash Service, or with any other person’s use of the TayoCash Service. You agree that you will not engage in any activity that interferes with or disrupts the TayoCash Service or the servers and networks that are connected to the TayoCash Service; and</p>

          <p>1.10 Use the TayoCash Service for any purpose that is illegal, unlawful, or prohibited by the T&C, or to solicit the performance of any illegal activity or other activity, which infringes on the rights of TayoCash or others. You further agree not to modify, rent, lease, loan, sell, distribute or create derivative works based on any content, material or information, either in whole or in part, available on or through the TayoCash Service, unless you have been specifically permitted to do so by TayoCash Service or by the owner of that content, material, or information in a separate agreement.</p>
        </div>
        <div class="paragraph">
          <h4>2. TayoCash User Content</h4>
          <p>2.1 As a TayoCash Member, you agree and undertake:
            <span>2.1.1 To be solely responsible and hold TayoCash free of liability to you, or to any third party, for any user content that you create, upload, transmit, or display while using the TayoCash Service. You agree and hereby undertake sole responsibility for the consequences of your actions and interactions using the TayoCash Service, and hereby stipulate admission of liability to TayoCash for whatever loss or damage TayoCash may suffer as a consequence of your use or misuse of the TayoCash Service;</span>

            <span>2.1.2 Not to share, create, upload, transmit, or display, using the TayoCash Service, any material, information, or user content, which is or may be covered by copyright, patent, trade secret, trademark, trade name, service mark, or any property rights, including privacy and/or publicity rights, unless you have the necessary licenses, rights, consents, and permissions to use, and to authorize TayoCash to use any and/or all user content that you create, upload, transmit, or display using the TayoCash Service;</span>

            <span>2.1.3 Not to use fictitious name and concealing true name for the purpose of committing and concealing crime, evading the execution of a judgment, or causing damage;</span>

            <span>2.1.4 Not to share, create, upload, transmit, or display, using the TayoCash Service, any material, information, or user content which:
              <span>2.1.4.1 Infringes on the intellectual property, privacy, and/or publicity rights of any third party;</span>

              <span>2.1.4.2 Is knowingly false and/or defamatory, inaccurate, abusive, vulgar, hateful, harassing, obscene, indecent, pornographic, profane, threatening, racist, gruesome, offensive to public morals, invasive of another’s privacy, or otherwise violative of any rule, regulation, or law;</span>

              <span>2.1.4.3 Contains any viruses, such as, but not limited to, Trojan horses, worms, time bombs, bots, ransomware, any malware, or any computer program, code, or routine that may or is intended to damage, detrimentally interfere with, surreptitiously intercept, or expropriate any system, data, or personal information; and/or</span>

              <span>2.1.4.4 Promotes or abets piracy.</span>
            </span>
          </p>

          <p>2.1.5 Not to:
            <span>2.1.5.1 Engage in pyramid, ponzi, or similar schemes, or other damaging schemes;</span>

            <span>2.1.5.2 Start or forward chain letters; and</span>

            <span>2.1.5.3 Conduct or aid in the conduct of surveys, contests, petitions, and the like, using the TayoCash Service, unless the same has been specifically designed and explicitly made available for such purpose.</span>
          </p>

          <p>2.1.6 Where relevant, to store and safeguard your password on the device and our servers in an encrypted form. This form of encryption disguises your password on the server, but still allows us to authenticate you when you sign into the TayoCash Service.</p>

          <p>2.1.7 To represent and warrant that you own or that you have the necessary licenses, rights, consents, and permissions to use, and to authorize and grant TayoCash perpetual, irrevocable, worldwide, royalty-free, and non-exclusive right, license, consent, and permission to use, reproduce, adapt, modify, translate, publish, publicly perform, publicly  display, distribute, or transmit over public networks and in various media any and/or all user content which you may create, upload, transmit, or display using the TayoCash Service, in a manner TayoCash may deem fit and necessary, including, but not limited to, making such user content available to a third party in compliance with a requirement of law, a rule or regulation; or making such user content available to any third party, including TayoCash affiliates and subsidiaries, for its use, reproduction, adaptation, modification, translation, publication, public performance, public display, distribution or transmission. You agree to hold TayoCash free from any liability, both under equity and the law, arising or that may arise out of its use of your user content or its use by a third party to which TayoCash Service made it available.</p>
        </div>
        <div class="paragraph">
          <h4>3. Third Party Content & Services</h4>
          <p>3.1 The TayoCash Service contains content provided by third-party services and resources. You acknowledge and agree that we are not responsible or liable for the availability or accuracy, appropriateness, completeness, or non-infringement of such third-party services; or the content, products, or services available on or through such third-party services. Unless specified by us, the availability of such third-party services does not imply any endorsement by us of such third-party services or the content, products, or  related services.</p>

          <p>3.2 You may not remove, alter or obscure any copyright, trademark, service mark or other notices of proprietary rights incorporated in or accompanying the application and the services, or in any third-party service.</p>

          <p>3.3 TayoCash makes no representation or warranties whatsoever about any service, content and/or any other resources provided by third parties.</p>
        </div>
        <div class="paragraph">
          <h4>4. Reserved Rights</h4>
          <p>We reserve, at our sole discretion but without obligation, the right to:</p>

          <p>4.1 Deny access to and/or discontinue the TayoCash Service or any component  thereof at any time, temporarily or permanently, without giving any reason and/or prior notice to you. You hereby irrevocably agree, affirm, and warrant to hold TayoCash free from any liability, both under equity and the law, arising or that may arise out of any such denial of access to or the discontinuance  of the TayoCash Service;</p>

          <p>4.2 Collect, screen, review, flag, filter, modify, block, refuse, or remove any and/or all information provided by any user, explicitly or implicitly, to and through the TayoCash Service, including but not limited to hardware information, IP address, browser-type related information, cookies, and the like. You hereby irrevocably agree, affirm, and warrant to hold the TayoCash Service free from any liability, both under equity and the law, arising or that may arise out of any such collection, screening, review, flagging, filtering, modification, blocking, refusal or removal of any and/or all information provided by any user to and through the TayoCash Service;</p>

          <p>4.3 Enhance, improve, develop, and introduce new features and functionalities to the TayoCash Service at any time and without prior notice. You hereby understand, agree, and affirm that any such enhancement, improvement, development, new feature, and/or new functionality to the TayoCash Service shall form part of the TayoCash Service as defined herein and thus shall likewise be covered by the T&C and Acceptable Use Policy and its subsequent revisions or amendments, as applicable;</p>

          <p>4.4 Verify, check, cross-refer, validate, and ascertain the veracity and truthfulness of all information supplied by you by acquiring, accessing, retrieving, or otherwise acquiring similar or additional information supplied by you to other third-party service providers, including, but not limited to, telecommunications providers, etc.  You hereby expressly, unequivocally, and voluntarily allow TayoCash to request for and secure such information, and expressly, unequivocally, and voluntarily  instruct such third-party providers to:

            <span>4.4.1 Receive and process TayoCash’s request;</span>

            <span>4.4.2 Favorably act at all times on any such request by producing the information requested; and</span>

            <span>4.4.3 When requested by TayoCash, provide the latter with certified digital or printed copies of the said information.</span>
          </p>

          <p>4.5 Send you or cause to send you service updates and/or messages, including SMS, notifications, email, and/or any data message transmission, informing you of enhancements, improvements, developments, features, functionalities, products, promotions, offers, advertisement, and/or any other information relative to the TayoCash Service. TayoCash makes no warranty of any kind, express or implied, for such service updates and/or messages, but you hereby agree to receive such service updates and/or messages and hold TayoCash free from any liability and/or claims for indemnification or damages that may arise;</p>

          <p>4.6 Set limitations to and charge fees and applicable taxes for the use of the  TayoCash Service, at any time and without prior notice. You further understand and agree that TayoCash reserves the right, at its sole discretion, to change the applicable fees, taxes, and charges levied for the use of the TayoCash Service, at any time and with prior written notice to you thirty (30) days prior to effective date of change in fees, charges or penalties; and</p>

          <p>4.7 To limit the provision, availability, quantity, and quality of any feature, product or service to any person or to anyone within the same geographic area, demographic profile, or any other market, commercial, and/or trading segments. You likewise  understand and agree that TayoCash reserves the right, at its sole discretion but without obligation, to administer and operate any and all of the TayoCash Service from any or various locations outside the Republic of the Philippines. You hereby understand, agree, and undertake sole responsibility for your continued access to or use of the TayoCash Service, as well as the results or consequences of such access and use, including the responsibility for compliance with applicable laws and the sole liability for non-compliance or any form of breach.</p>
        </div>

        <div class="buttons">
          <a href="/helpcenter" class="yellow">BACK</a>
          <a href="/privacypolicy" class="white">PRIVACY POLICY</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'termsandconditions'
}
</script>

<style scoped lang='scss'></style>
